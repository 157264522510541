import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Strategy({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        strokeWidth="3"
        d="M154.594 433.321H61.411C56.3957 433.321 52.6921 428.372 54.5837 423.821C63.3606 402.777 83.9802 390.721 107.999 390.721C132.025 390.721 152.644 402.777 161.421 423.821C163.313 428.372 159.609 433.321 154.594 433.321ZM79.0083 348.121C79.0083 332.458 92.0179 319.721 107.999 319.721C123.987 319.721 136.99 332.458 136.99 348.121C136.99 363.784 123.987 376.521 107.999 376.521C92.0179 376.521 79.0083 363.784 79.0083 348.121ZM178.685 430.736C173.416 406.888 156.935 389.287 135.243 381.299C146.738 372.232 153.442 357.571 150.978 341.518C148.122 322.894 132.308 307.992 113.217 305.819C86.8648 302.816 64.513 322.909 64.513 348.121C64.513 361.54 70.862 373.496 80.7623 381.299C59.0628 389.287 42.5889 406.888 37.3126 430.736C35.3992 439.406 42.5309 447.521 51.5832 447.521H164.415C173.474 447.521 180.606 439.406 178.685 430.736Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="3"
        d="M438.594 433.321H345.411C340.396 433.321 336.692 428.372 338.584 423.821C347.361 402.777 367.98 390.721 391.999 390.721C416.025 390.721 436.644 402.777 445.421 423.821C447.313 428.372 443.609 433.321 438.594 433.321ZM363.008 348.121C363.008 332.458 376.018 319.721 391.999 319.721C407.987 319.721 420.99 332.458 420.99 348.121C420.99 363.784 407.987 376.521 391.999 376.521C376.018 376.521 363.008 363.784 363.008 348.121ZM462.685 430.736C457.416 406.888 440.935 389.287 419.243 381.299C430.738 372.232 437.442 357.571 434.978 341.518C432.122 322.894 416.308 307.992 397.217 305.819C370.865 302.816 348.513 322.909 348.513 348.121C348.513 361.54 354.862 373.496 364.762 381.299C343.063 389.287 326.589 406.888 321.313 430.736C319.399 439.406 326.531 447.521 335.583 447.521H448.415C457.474 447.521 464.606 439.406 462.685 430.736Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="3"
        d="M302.5 187H197.505C191.854 187 187.681 181.424 189.813 176.296C199.702 152.584 222.935 139 249.999 139C277.07 139 300.304 152.584 310.193 176.296C312.325 181.424 308.151 187 302.5 187ZM217.333 90.9999C217.333 73.3519 231.992 58.9999 249.999 58.9999C268.014 58.9999 282.664 73.3519 282.664 90.9999C282.664 108.648 268.014 123 249.999 123C231.992 123 217.333 108.648 217.333 90.9999ZM329.645 184.088C323.708 157.216 305.138 137.384 280.696 128.384C293.648 118.168 301.202 101.647 298.425 83.5595C295.208 62.5755 277.389 45.7838 255.879 43.3358C226.186 39.9518 201.001 62.5919 201.001 90.9999C201.001 106.12 208.154 119.592 219.31 128.384C194.859 137.384 176.297 157.216 170.352 184.088C168.196 193.856 176.232 203 186.432 203H313.566C323.774 203 331.809 193.856 329.645 184.088Z"
      />
      <motion.circle variants={iconPath} cx="250" cy="283" r="23.9155" strokeWidth="10" />
      <motion.line
        variants={iconPath}
        x1="252"
        y1="203"
        x2="252"
        y2="257.085"
        strokeWidth="10"
      />
      <motion.line
        variants={iconPath}
        x1="263.835"
        y1="304.147"
        x2="353.975"
        y2="397.668"
        strokeWidth="10"
      />
      <motion.line
        variants={iconPath}
        x1="237.985"
        y1="305.724"
        x2="152.351"
        y2="403.752"
        strokeWidth="10"
      />
    </motion.svg>
  );
}
