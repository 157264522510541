import React from "react";
import Layout from "../components/Layout";
import Meta from "../components/Meta";
import HeroSection from "../components/HeroSection";
import * as styles from "../styles/services.module.css";
import * as heroStyles from "../styles/hero-section.module.css";
import Card from "../components/Card";
import LinearReveal from "../components/animation/LinearReveal";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import CustomerAlignment from "../components/icons/CustomerAlignment";
import HandGlobe from "../components/icons/HandGlobe";
import Strategy from "../components/icons/Strategy";
import ScreeningCandidate from "../components/icons/ScreeningCandidate";
import Support from "../components/icons/Support";

export default function TalentAcquition() {
  const recruitmentSteps = [
    {
      icon: <CustomerAlignment dimension="100px" />,
      title: "Customer Alignment",
      description: "Understand Technology & Solution needs",
    },
    {
      icon: <HandGlobe dimension="100px" />,
      title: "Labor Market Intelligence",
      description: "Deep understanding of the market",
    },
    {
      icon: <Strategy dimension="100px" />,
      title: "Strategic Sourcing Process",
      description: "Proprietary network and referral based sourcing",
    },
    {
      icon: <ScreeningCandidate dimension="100px" />,
      title: "Candidate Screening",
      description: "Comprehensive technical screening",
    },
    {
      icon: <Support dimension="100px" />,
      title: "Onboarding & Retention Support",
      description: "Priorities of the employees",
    },
  ];

  return (
    <Layout>
      <Meta
        title="Talent Acquisition Services - DBT"
        description="Helping Organizations Match Great Talent to Great Opportunity"
      />
      <HeroSection
        title="Talent Acquisition Services"
        titleClass={"text-primary" + " " + heroStyles.heroTitle}
        description="Helping Organizations Match Great Talent to Great Opportunity"
        descClass={"has-text-white-bis " + heroStyles.heroDesc}
        imageClass={heroStyles.talentImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />{" "}
      <section>
        <LinearReveal>
          <h1 className={"text-primary has-text-centered " + styles.heading}>
            Our Recruitment Process
          </h1>
        </LinearReveal>
        <LinearReveal>
          <div className="is-flex is-justify-content-center is-flex-wrap-wrap mx-auto px-1">
            {recruitmentSteps.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass={"text-heading " + styles.talentCardTitle}
                  cardStyle={{ paddingTop: "30px" }}
                  cardContentClass="px-2"
                  cardClass={styles.talentCard}
                  content={card.description}
                  contentClass="has-text-centered text-description"
                />
              </div>
            ))}
          </div>
        </LinearReveal>

        <LinearReveal>
          <h2
            className={
              "sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Recruiting Process
          </h2>
        </LinearReveal>

        <LinearReveal>
          <div className="px-5" style={{ maxWidth: "1000px", margin: "auto" }}>
            <p className={styles.description2}>
              <strong className="text-heading">Customer Alignment - </strong> We invest
              the time to understand your business and culture. Our holistic approach
              enables us to assess your service requirements, and we work with you to
              establish a compelling employee value proposition that is positioned to
              attract top talent.
            </p>

            <p className={styles.description2}>
              <strong className="text-heading">Labor Market Intelligence - </strong> In
              every local market we serve, we know the top professionals, what they earn,
              where to find them, and when they are available. We also understand their
              priorities and what it takes to recruit and retain them.
            </p>

            <p className={styles.description2}>
              <strong className="text-heading">Strategic Sourcing Process - </strong> By
              leveraging our proprietary network of job candidates and a referral-based
              sourcing strategy, we increase our speed and effectiveness in finding top
              talent to meet your hiring needs.
            </p>

            <p className={styles.description2}>
              <strong className="text-heading">Robust Candidate Screening - </strong> We
              screen all candidates through a solid qualification process, including
              resume matching, behavioral interviews, and supervisory reference checks. We
              can also perform certification verification, background checks, and more.
            </p>

            <p className={styles.description2}>
              <strong className="text-heading">Onboarding & Retention Support - </strong>{" "}
              We speed the time to productivity for our placements by facilitating a
              smooth onboarding program. For contract positions, we also remain in close
              contact with the client and our consultant, proactively promoting employee
              engagement, performance, and retention throughout each assignment.
            </p>
          </div>
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Open Positions
          </h2>
          <div className="is-flex is-justify-content-center">
            <Link to="/applyjob">
              <motion.button
                className="button px-4"
                style={{ transitionDuration: "0s" }}
                initial={{ border: "2px solid var(--secondary)", color: "#4a4a4a" }}
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "var(--secondary)",
                  color: "#fff",
                }}
              >
                Apply for Job
              </motion.button>
            </Link>
          </div>
        </LinearReveal>
      </section>
    </Layout>
  );
}
